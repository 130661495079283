import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/CommerceClub/Mockstock/1.jpg';
// import p2 from 'assests/Photos/Clubs/CommerceClub/Mockstock/2.jpg';
// import p3 from 'assests/Photos/Clubs/CommerceClub/Mockstock/3.jpg';
// import p4 from 'assests/Photos/Clubs/CommerceClub/Mockstock/4.jpg';
// import p5 from 'assests/Photos/Clubs/CommerceClub/Mockstock/5.jpg';
// import p6 from 'assests/Photos/Clubs/CommerceClub/Mockstock/6.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SideBarAssoc2024 from '../CommerceAssociation2023/components/SideBarAssoc2023/SideBarAssoc2024';
import Archive2023 from '../CommerceAssociation2023/Archive2023';

const MockStock = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_url}/home/events-activities/association/CommerceClub/Mockstock/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/CommerceClub/Mockstock/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/CommerceClub/Mockstock/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/CommerceClub/Mockstock/4.webp`;
    const p5 = `${config.base_image_url}/home/events-activities/association/CommerceClub/Mockstock/5.webp`;
    const p6 = `${config.base_image_url}/home/events-activities/association/CommerceClub/Mockstock/6.webp`;
  

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        }, 
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        }, 
        
    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                MOCK STOCK
                                </Typography>
                              
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: 9 - 12 &  Date: 18 June 2024
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                               “In investing, what is comfortable is rarely profitable.” – Robert Arnott
                                <br /><br />
                            </Typography>
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Commerce Association of National Public School, Yeshwanthpur organised ‘MOCK STOCK’ on 18 JUNE for the students of classes 9 to 12.  As the name suggests, the event simulated real stock market scenarios without involving actual money. These events are insightful and fun, offering a taste of a real-world trading without financial consequences.
                                <br/>
                                The teams created an innovative comprehensive project plan dealing with real estate development that would be practical and profitable. The event highlighted the need to demonstrate a combination of strategic thinking, financial acumen and a good understanding of market dynamics. It was a valuable learning experience that bridged theory with real-world application, enhancing their financial literacy and analytical abilities. 
                                </Typography>
                                <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                              ><br />
                                "The individual investor should act consistently as an investor and not as a speculator." — Ben Graham
                                <br />
                            </Typography>
                            </Box>
                            
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 500}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                              <SideBarAssoc2024/>
                        </Box>
                        <Box marginBottom={4}>
                        <Archive2023 />
                        </Box>
                      
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default MockStock;